import HabitDescription from '../views/HabitDescription'
import beforeEnter from './beforeEnter'

export default {
  path: '/habit-description',
  name: 'habit-description',
  component: HabitDescription,
  meta: {
    title: '習慣の編集',
    isSubview: true
  },
  beforeEnter: beforeEnter()
}
