<template>
  <div
    class="button"
    @click="exec"
    :class="dynamicClass"
  >
    <font-awesome-icon
      v-if="icon"
      :icon="icon"
      class="icon"
      size="xs"
    />
    {{ caption }}
  </div>
</template>

<script>
export default {
  props: {
    action: {
      type: Function
    },

    caption: {
      type: String
    },

    color: {
      type: String
    },

    icon: {
      type: String
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    dynamicClass () {
      let classes = ''
      if (this.color) {
        classes += this.color
      }

      if (this.disabled) {
        classes += ' disabled'
      }
      return classes
    }
  },

  methods: {
    exec () {
      if (this.disabled) return
      if (this.action) this.action()
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  width: 250px;
  height: 40px;
  color: white;
  background: #2c3e50;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.disabled {
  background: gray;
  cursor: default;
}

.blue {
  background: #2980b9;
}

.icon {
  margin-right: 10px;
}
</style>
