<template>
  <list-input-text
    type="text"
    placeholder="本の１ページを読む"
    :get="get"
    :set="set"
  />
</template>

<script>
import ListInputText from '@/lib/List/Input/Text'

export default {
  components: {
    ListInputText
  },

  computed: {
    get () {
      return () => {
        return this.$store.state.habit.smallAction
      }
    },

    set () {
      return (value) => {
        this.$store.commit('habit/setSmallAction', value)
      }
    }
  }
}
</script>
