<template>
  <div>
    <div class="message">
      {{ message }}
    </div>

    <div class="alternate-action">
      {{ habit.get('alternateAction') }}
    </div>
  </div>
</template>

<script>
import Mixin from './Mixin'
import emoji from 'node-emoji'

export default {
  mixins: [
    Mixin
  ],

  computed: {
    message () {
      return emoji.emojify('小さな行動ができないときは？:crying_cat_face:')
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 2px;
}

.alternate-action {
  font-weight: 500;
}
</style>
