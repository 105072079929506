<template>
  <div class="habit-description">
    <explanation
      caption="詳細"
      description="習慣の詳細を設定します。"
    />
    <input-description />
    <button-set-description />
  </div>
</template>

<script>
import Explanation from '@/lib/Explanation/index'
import InputDescription from '@/components/HabitDescription/InputDescription'
import ButtonSetDescription from '@/components/HabitDescription/ButtonSetDescription'

export default {
  components: {
    Explanation,
    InputDescription,
    ButtonSetDescription
  }
}
</script>
