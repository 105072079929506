<template>
  <div>
    <habit-name />
    <habit-small-action />
    <habit-alternate-action />
    <habit-description />
    <button-save />
  </div>
</template>

<script>
import HabitName from '@/components/HabitNew/Name'
import HabitSmallAction from '@/components/HabitNew/SmallAction'
import HabitAlternateAction from '@/components/HabitNew/AlternateAction'
import HabitDescription from '@/components/HabitNew/Description'
import ButtonSave from '@/components/HabitNew/ButtonSave'

export default {
  components: {
    HabitName,
    HabitSmallAction,
    HabitAlternateAction,
    HabitDescription,
    ButtonSave
  }
}
</script>
