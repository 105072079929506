<template>
  <div class="folded">
    <component
      :is="foldedComponent"
      :item="foldedItem"
    />
  </div>
</template>

<script>
import Today from './Today'

export default {
  components: {
    Today
  },

  props: {
    foldedComponent: {
      type: String
    },

    foldedItem: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.folded {
  margin-top: 10px;
  margin-left: 40px;
}
</style>
