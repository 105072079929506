<template>
  <button-center
    caption="習慣を更新する"
    :action="save"
    color="blue"
  />
</template>

<script>
import ButtonCenter from '@/lib/Button/Center'

export default {
  components: {
    ButtonCenter
  },

  methods: {
    async save () {
      const result = await this.$store.dispatch('habit/save')
      if (!result) return

      this.$router.back()
    }
  }
}
</script>
