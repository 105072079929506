<template>
  <loading
    :active.sync="isLoading"
    :can-cancel="true"
  >
  </loading>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    Loading
  },

  computed: {
    isLoading () {
      return this.$store.state.loading.isLoading
    }
  }
}
</script>
