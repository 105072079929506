<template>
  <div class="thank-you">
    <title-without-login>{{ thankYou }}
    </title-without-login>

    <message-basic>{{ appFromHome }}
    </message-basic>

    <message-basic>{{ please }}
    </message-basic>
  </div>
</template>

<script>
import emoji from 'node-emoji'

import TitleWithoutLogin from '@/lib/Title/WithoutLogin'
import MessageBasic from '@/lib/Message/Basic'

export default {
  components: {
    TitleWithoutLogin,
    MessageBasic
  },

  computed: {
    thankYou () {
      return 'ありがとうございます！🎉'
    },

    appFromHome () {
      return emoji.emojify('今後はホーム画面からご利用いただけます:ok_woman:')
    },

    please () {

      const text = `もしアプリが起動しないようであれば、お手数ですが再度インストールしてください:pray:`
      return emoji.emojify(text)
    }
  }
}
</script>

<style lang="scss" scoped>
.thank-you {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 20px;
}
</style>
