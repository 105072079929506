<template>
  <div class="title">
    {{ title }}
  </div>
</template>

<script>
export default {
  computed: {
    title () {
      return this.$store.state.overlay.title
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.2rem;
}
</style>
