import Parse from 'parse'

const {
  VUE_APP_SERVER_URL,
  VUE_APP_APPLICATION_ID,
  VUE_APP_JAVASCRIPT_KEY
} = process.env

Parse.serverURL = VUE_APP_SERVER_URL
Parse.initialize(
  VUE_APP_APPLICATION_ID,
  VUE_APP_JAVASCRIPT_KEY
)

export default Parse
