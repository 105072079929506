import HabitEdit from '../views/HabitEdit'
import beforeEnter from './beforeEnter'

export default {
  path: '/habit-edit',
  name: 'habit-edit',
  component: HabitEdit,
  meta: {
    title: '習慣',
    isSubview: true,
    canDelete: true
  },
  beforeEnter: beforeEnter()
}
