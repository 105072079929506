<template>
  <div>
    <list-caption
      caption="カレンダー"
    />
  </div>
</template>

<script>
import ListCaption from '@/lib/List/Caption'

export default {
  components: {
    ListCaption
  }
}
</script>
