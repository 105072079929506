<template>
  <div class="line-main">
    <list-foldable-icon
      v-if="foldable"
      :index="index"
      :store="store"
    />

    <div
      class="line-right"
      @click="exec"
    >
      <div :class="done">
        <list-caption :caption="caption" />
        <list-description
          v-if="description"
          :description="description"
        />
      </div>

      <list-checkbox
        class="right"
        v-if="checkbox"
        :checked="checked"
      />
    </div>
  </div>
</template>

<script>
import ListCaption from './Caption'
import ListDescription from './Description'
import ListCheckbox from './Checkbox'
import ListFoldableIcon from './FoldableIcon'

export default {
  components: {
    ListCaption,
    ListDescription,
    ListCheckbox,
    ListFoldableIcon
  },

  props: {
    index: {
      type: Number
    },

    store: {
      type: String
    },

    caption: {
      type: String
    },

    description: {
      type: String
    },

    checkbox: {
      type: Boolean
    },

    checked: {
      type: Boolean
    },

    foldable: {
      type: Boolean
    },

    action: {
      type: Function
    }
  },

  computed: {
    done () {
      if (this.checked) return 'done'
      return ''
    }
  },

  methods: {
    exec () {
      if (this.action) this.action()
    }
  }
}
</script>

<style lang="scss" scoped>
.line-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.line-right {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.done {
  text-decoration: line-through;
}
</style>
