<template>
  <div
    class="background"
    @click="hide"
  >
    <div class="frame">
      <overlay-title class="layout-title" />
      <overlay-message />
    </div>
  </div>
</template>

<script>
import OverlayTitle from './Title'
import OverlayMessage from './Message'

export default {
  components: {
    OverlayTitle,
    OverlayMessage
  },

  methods: {
    hide () {
      this.$store.dispatch('overlay/hide')
    }
  }
}
</script>

<style lang="scss" scoped>
.background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(44, 62, 80, 0.6);

  display: flex;
  justify-content: center;
  align-items: center;
}

.frame {
  width: 90%;
  height: 25%;
  background: white;
  border-radius: 2px;
  padding: 24px;
}

.layout-title {
  margin-bottom: 10px;
}
</style>
