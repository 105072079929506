<template>
  <list-basic
    caption="代わりの行動"
    :description="$store.state.habit.alternateAction"
    :action="action"
  />
</template>

<script>
import ListBasic from '@/lib/List/Basic/index'

export default {
  components: {
    ListBasic
  },

  methods: {
    action () {
      this.$router.push({ name: 'habit-alternate-action' })
    }
  }
}
</script>
