<template>
  <div>
    <div class="alternate">
      代わりの行動
    </div>

    <div class="alternate-content">
      {{ item.get('alternateAction') }}
    </div>

    <div class="description">
      説明
    </div>

    <div class="description-content">
      {{ item.get('description') }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.alternate {
  border-top: 1px solid gray;
  padding-top: 10px;
  font-size: 0.75rem;
  font-weight: 500;
  margin-bottom: 2px;
}

.alternate-content {
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.description {
  border-top: 1px solid gray;
  padding-top: 10px;
  font-size: 0.75rem;
  font-weight: 500;
  margin-bottom: 2px;
}

.description-content {
  font-size: 0.9rem;
}


</style>
