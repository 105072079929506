<template>
  <font-awesome-icon
    :icon="icon"
    size="lg"
    class="icon"
  />
</template>

<script>
export default {
  props: {
    icon: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  margin-bottom: 5px;
}
</style>
