<template>
  <div class="habit-alternate-action">
    <explanation
      caption="代わりの行動"
      description="小さな行動ができないときの代わりの行動です。"
    />
    <input-alternate-action />
    <button-set-alternate-action />
  </div>
</template>

<script>
import Explanation from '@/lib/Explanation/index'
import InputAlternateAction from '@/components/HabitAlternateAction/InputAlternateAction'
import ButtonSetAlternateAction from '@/components/HabitAlternateAction/ButtonSetAlternateAction'

export default {
  components: {
    Explanation,
    InputAlternateAction,
    ButtonSetAlternateAction
  }
}
</script>
