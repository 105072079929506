<template>
  <div class="icon-container">
    <font-awesome-icon
      icon="arrow-left"
      size="lg"
      @click="back"
    />
  </div>
</template>

<script>
export default {
  methods: {
    back () {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-container {
  height: 64px;
  width: 64px;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
