<template>
  <list-basic
    caption="バージョン"
    :action="action"
  />
</template>

<script>
import ListBasic from '@/lib/List/Basic/index'

export default {
  components: {
    ListBasic
  },

  methods: {
    action () {
      this.$store.dispatch('overlay/show', {
        title: 'バージョン',
        message: process.env.VUE_APP_VERSION
      })
    }
  }
}
</script>
