<template>
  <div class="login">
    <login-title />

    <input-id />
    <input-password />
    <button-login />
  </div>
</template>

<script>
import LoginTitle from '@/components/Login/Title'
import InputId from '@/components/Login/InputId'
import InputPassword from '@/components/Login/InputPassword'
import ButtonLogin from '@/components/Login/ButtonLogin'

export default {
  components: {
    LoginTitle,
    InputId,
    InputPassword,
    ButtonLogin
  },

  computed: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_reset.scss';

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 6px;
  border: 1px solid #bdc3c7;
  background: white;
  width: 250px;
}
</style>
