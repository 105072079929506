<template>
  <input
    class="reset input"
    type="text"
    v-model="id"
  >
</template>

<script>
export default {
  computed: {
    id: {
      get () {
        return this.$store.state.login.id
      } ,

      set (id) {
        this.$store.commit('login/setId', id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_input.scss';
</style>
