import Parse from '@/plugins/parse'

export default {
  async save ({ state, commit, dispatch }) {
    let model = state.model
    let message = '習慣を更新しました。'

    if (!model) {
      const Habit = Parse.Object.extend('Habit')
      model = new Habit()
      message = '習慣を作成しました。'
    }

    model.set('name', state.name)
    model.set('smallAction', state.smallAction)
    model.set('alternateAction', state.alternateAction)
    model.set('description', state.description)

    try {
      dispatch('loading/show', null, { root: true })
      await model.save()
      dispatch('loading/hide', null, { root: true })
      commit('setIsReloadRequired', true)
      commit('setIsLoadingRequired', false)
      dispatch(
        'toast/show',
        message,
        { root: true }
      )
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }
}
