<template>
  <div class="info">
    <version />
  </div>
</template>

<script>
import Version from '@/components/Info/Version'

export default {
  components: {
    Version
  }
}
</script>
