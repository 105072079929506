<template>
  <div class="password-change">
    <explanation
      caption="パスワード変更"
      description="ログインパスワードを変更します。"
    />
    <input-password />
    <button-change-password />
  </div>
</template>

<script>
import Explanation from '@/lib/Explanation/index'
import InputPassword from '@/components/PasswordChange/InputPassword'
import ButtonChangePassword from '@/components/PasswordChange/ButtonChangePassword'

export default {
  components: {
    Explanation,
    InputPassword,
    ButtonChangePassword
  }
}
</script>
