export default {
  setModel (state, model) {
    state.model = model
  },

  setList (state, list) {
    list.forEach(item => {
      item.isExpanded = false
    })
    state.list = list
  },

  switchIsExpanded (state, index) {
    state.list[index].isExpanded = !state.list[index].isExpanded
  },

  setIsReloadRequired (state, isReloadRequired) {
    state.isReloadRequired = isReloadRequired
  },

  setIsLoadingRequired (state, isLoadingRequired) {
    state.isLoadingRequired = isLoadingRequired
  }
}
