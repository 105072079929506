<template>
  <div>
    <habit-name />
    <habit-small-action />
    <habit-alternate-action />
    <habit-description />
    <button-save />
  </div>
</template>

<script>
import HabitName from '@/components/HabitEdit/Name'
import HabitSmallAction from '@/components/HabitEdit/SmallAction'
import HabitAlternateAction from '@/components/HabitEdit/AlternateAction'
import HabitDescription from '@/components/HabitEdit/Description'
import ButtonSave from '@/components/HabitEdit/ButtonSave'

export default {
  components: {
    HabitName,
    HabitSmallAction,
    HabitAlternateAction,
    HabitDescription,
    ButtonSave
  },

  created () {
    this.$store.commit('nav/setDeleteAction', async () => {
      await this.$store.dispatch('habit/destroy')
      this.$router.back()
    })
  }
}
</script>
