<template>
  <button-basic
    caption="ログイン"
    :action="login"
  />
</template>

<script>
import ButtonBasic from '@/lib/Button/Basic'

export default {
  components: {
    ButtonBasic
  },

  methods: {
    async login () {
      const result = await this.$store.dispatch('login/exec')
      if (result) this.$router.push({ name: 'home' })
    }
  }
}
</script>
