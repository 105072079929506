const state = {
  show: false,
  title: '',
  message: ''
}

const mutations = {
  setShow (state, show) {
    state.show = show
  },

  setTitle (state, title) {
    state.title = title
  },

  setMessage (state, message) {
    state.message = message
  }
}

const actions = {
  show ({ commit }, { message, title }) {
    commit('setTitle', title)
    commit('setMessage', message)
    commit('setShow', true)
  },

  hide ({ commit }) {
    commit('setShow', false)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
