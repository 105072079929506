<template>
  <div class="signup">
    <signup-title />

    <input-id />
    <input-password />
    <button-signup />
  </div>
</template>

<script>
import SignupTitle from '@/components/Signup/Title'
import InputId from '@/components/Signup/InputId'
import InputPassword from '@/components/Signup/InputPassword'
import ButtonSignup from '@/components/Signup/ButtonSignup'

export default {
  components: {
    SignupTitle,
    InputId,
    InputPassword,
    ButtonSignup
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_reset.scss';

.signup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 6px;
  border: 1px solid #bdc3c7;
  background: white;
  width: 250px;
}
</style>
