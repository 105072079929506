import store from '@/store/index'

export default {
  path: '/logout',
  name: 'logout',
  beforeEnter: (to, from, next) => {
    store.dispatch('logout')
    next({ name: 'login' })
  }
}
