<template>
  <div class="guide">
    <habit-name class="layout-name" />
    <message class="layout-message" />
    <habit-small-action class="layout-small-action" />
    <habit-alternate-action class="layout-alternate-action" />

    <button-next
      :item="habit"
    />
  </div>
</template>

<script>
import Mixin from './Mixin'
import Message from './Message'

import HabitName from './Name'
import HabitSmallAction from './SmallAction'
import HabitAlternateAction from './AlternateAction'
import ButtonNext from './ButtonNext'

export default {
  mixins: [
    Mixin
  ],

  components: {
    Message,
    HabitName,
    HabitSmallAction,
    HabitAlternateAction,
    ButtonNext
  }
}
</script>

<style lang="scss" scoped>
.guide {
  text-align: center;
}

.layout-name {
  margin-bottom: 16px;
}

.layout-message {
  margin-bottom: 30px;
}

.layout-small-action {
  margin-bottom: 24px;
}

.layout-alternate-action {
  margin-bottom: 24px;
}
</style>
