<template>
  <icon-base
    caption="習慣"
    icon="user"
    route="habit"
  />
</template>

<script>
import IconBase from './Base/index'

export default {
  components: {
    IconBase
  }
}
</script>
