<template>
  <div>
    {{ caption }}
  </div>
</template>

<script>
export default {
  props: {
    caption: {
      type: String
    }
  }
}
</script>
