<template>
  <div
    class="input-container"
    @click="focus"
    @focusout="focusout"
    :style="adjustPadding"
  >
    <component
      :is="component"
      :get="get"
      :set="set"
    />
  </div>
</template>

<script>
import Mixin from './Mixin'
import Normal from './Normal'
import Focused from './Focused'

export default {
  mixins: [
    Mixin
  ],

  components: {
    Normal,
    Focused
  },

  props: {
    placeholder: {
      type: String,
      default: ''
    }
  },

  computed: {
    isFocused () {
      return this.$store.state.habit.focus.description
    },

    component () {
      if (this.isFocused) return 'focused'
      return 'normal'
    },

    adjustPadding () {
      if (this.isFocused) return 'padding-bottom: 5px'
      return ''
    }
  },

  methods: {
    focus () {
      this.$store.dispatch('habit/focus', 'description')
    },

    focusout () {
      this.$store.dispatch('habit/focusout', 'description')
    }
  }
}
</script>

<style lang="scss" scoped>
.input-container {
  padding: 10px;
}
</style>
