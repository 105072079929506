<template>
  <div class="icon-right">
    <font-awesome-icon
      icon="plus"
      size="lg"
      @click="habitNew"
    />
  </div>
</template>

<script>
export default {
  methods: {
    habitNew () {
      this.$store.dispatch('habit/resetAttrs')
      this.$router.push({ name: 'habit-new' })
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-right {
  width: 64px;
  height: 64px;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
