<template>
  <icon-base
    caption="ホーム"
    icon="home"
    :action="action"
    route="home"
  />
</template>

<script>
import IconBase from './Base/index'

export default {
  components: {
    IconBase
  },

  methods: {
    action () {
    }
  }
}
</script>
