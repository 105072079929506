<template>
  <div
    class="icon-container"
    @click="exec"
  >
    <font-awesome-icon
      :icon="icon"
      size="lg"
      class="icon"
    />
    {{ caption }}
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String
    },

    caption: {
      type: String
    },

    action: {
      type: Function
    }
  },

  methods: {
    exec () {
      this.action()
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-container{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d2dae2;
  padding: 16px;
  padding-left: 0;
  margin-left: 10px;
  cursor: pointer;
}

.icon {
  margin-right: 10px;
}
</style>
