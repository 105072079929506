import Parse from '@/plugins/parse'

export default (action) => {
  return (to, from, next) => {
    const user = Parse.User.current()
    if (!user) next({ name: 'login' })

    if (action) action(to.params)
    next()
  }
}
