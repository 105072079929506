import Vue from 'vue'
import Vuex from 'vuex'

import habit from './modules/habit/index'
import loading from './modules/loading/index'
import passwordChange from './modules/passwordChange/index'
import today from './modules/today/index'
import layout from './modules/layout'
import login from './modules/login'
import nav from './modules/nav'
import overlay from './modules/overlay'
import signup from './modules/signup'
import toast from './modules/toast'
import draggable from './modules/draggable'
import home from './modules/home'

import actions from './actions'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    habit,
    loading,
    passwordChange,
    today,
    layout,
    login,
    nav,
    overlay,
    signup,
    toast,
    draggable,
    home
  },

  actions
})
