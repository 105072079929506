<template>
  <div class="toast">
    <div id="toast-message" ref="message">
      {{ message }}
    </div>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js'

export default {
  mounted () {
    anime({
      targets: '#toast-message',
      opacity: 1,
      duration: 300,
      easing: 'easeOutQuad'
    })

    setTimeout(() => {
      anime({
        targets: '#toast-message',
        opacity: 0,
        duration: 400,
        easing: 'easeOutQuad',
        complete: () => {
          this.$store.dispatch('toast/hide')
        }
      })
    }, 3000)
  },

  computed: {
    message () {
      return this.$store.state.toast.message
    }
  }
}
</script>

<style lang="scss" scoped>
.toast {
  width: 100vw;
  position: absolute;
  bottom: 100px;
  display: flex;
  justify-content: center;
}

#toast-message {
  width: 300px;
  height: 40px;

  opacity: 0.1;
  background: rgba(44, 62, 80, 0.8);
  color: white;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
