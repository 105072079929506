import Info from '../views/Info'
import beforeEnter from './beforeEnter'

export default {
  path: '/info',
  name: 'info',
  component: Info,
  meta: {
    title: '情報',
    isSubview: true
  },
  beforeEnter: beforeEnter()
}
