const state = {
  title: '',
  isSubview: false,
  canDelete: false,
  deleteAction: null
}

const mutations = {
  setTitle (state, title) {
    state.title = title
  },

  setIsSubview (state, isSubview) {
    state.isSubview = isSubview
  },

  setCanDelete (state, canDelete) {
    state.canDelete = canDelete
  },

  setDeleteAction (state, deleteAction) {
    state.deleteAction = deleteAction
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
