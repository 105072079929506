import Parse from '@/plugins/parse'

export default {
  logout ({ dispatch }) {
    Parse.User.logOut().then(() => {
      dispatch('toast/show', 'ログアウトしました')
    }).catch((error) => {
      console.log(error.code, error)
    })
  }
}
