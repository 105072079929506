<template>
  <list-icon
    caption="ログアウト"
    icon="sign-out-alt"
    :action="logout"
  />
</template>

<script>
import ListIcon from '@/lib/List/Icon/index'

export default {
  components: {
    ListIcon
  },

  methods: {
    logout () {
      this.$router.push({ name: 'logout' })
    }
  }
}
</script>
