import HabitAlternateAction from '../views/HabitAlternateAction'
import beforeEnter from './beforeEnter'

export default {
  path: '/habit-alternate-action',
  name: 'habit-alternate-action',
  component: HabitAlternateAction,
  meta: {
    title: '習慣の編集',
    isSubview: true
  },
  beforeEnter: beforeEnter()
}
