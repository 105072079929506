<template>
  <div class="title">
    {{ message }}
  </div>
</template>

<script>
export default {
  computed: {
    message () {
      return this.$store.state.overlay.message
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.2rem;
}
</style>
