<template>
  <list-basic
    :caption="caption"
    :description="description"
  />
</template>

<script>
import ListBasic from '@/lib/List/Basic/index'

export default {
  components: {
    ListBasic
  },

  props: {
    caption: {
      required: true,
      type: String
    },

    description: {
      required: true,
      type: String
    }
  }
}
</script>
