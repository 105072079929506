<template>
  <div id="app">
    <the-nav v-if="noNav" />
    <router-view
      class="main"
    />
    <the-tab v-if="noTab" />
    <the-toast v-if="toastShow" />
    <the-overlay v-if="overlayShow" />
    <the-loading />
  </div>
</template>

<script>
import TheNav from '@/components/TheNav/index'
import TheTab from '@/components/TheTab/index'
import TheToast from '@/components/TheToast/index'
import TheOverlay from '@/components/TheOverlay/index'
import TheLoading from '@/components/TheLoading/index'

export default {
  components: {
    TheNav,
    TheTab,
    TheToast,
    TheOverlay,
    TheLoading
  },

  created () {
    this.$store.dispatch('loading/hide')
  },

  computed: {
    noNav () {
      return this.$store.getters['layout/noNav'](this.$route.name)
    },

    noTab () {
      return this.$store.getters['layout/noTab'](this.$route.name)
    },

    toastShow () {
      return this.$store.state.toast.show
    },

    overlayShow () {
      return this.$store.state.overlay.show
    }
  }
}
</script>

<style lang="scss">
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Hiragino Kaku Gothic Pro','ヒラギノ角ゴ Pro W3','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;
}

body {
  margin: 0;
}
</style>

<style lang="scss" scoped>
* {
  color: #1e272e;
}

#app {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  background: #f1f2f6;
  overflow: hidden;
}

.main {
  height: calc(100vh - 64px - 64px);
}
</style>
