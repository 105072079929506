<template>
  <div class="layout">
    <button-basic
      :caption="caption"
      :action="action"
      :disabled="disabled"
      :color="color"
    />
  </div>
</template>

<script>
import ButtonBasic from '@/lib/Button/Basic'

export default {
  components: {
    ButtonBasic
  },

  props: {
    action: {
      type: Function
    },

    caption: {
      type: String
    },

    color: {
      type: String
    },

    disabled: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
