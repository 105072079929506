export default {
  async save ({ state, commit }, model) {
    model = model || state.model
    model.set('done', !model.get('done'))

    try {
      await model.save()
      commit('setIsReloadRequired', true)
      commit('setIsLoadingRequired', false)
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }
}
