const state = {
  component: 'start'
}

const mutations = {
  setComponent (state, component) {
    state.component = component
  }
}

const actions = {
  reset ({ commit }) {
    commit('setComponent', 'start')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
