<template>
  <div class="habit-name">
    <explanation
      caption="習慣名"
      description="習慣の名前を設定します。"
    />
    <input-name />
    <button-set-name />
  </div>
</template>

<script>
import Explanation from '@/lib/Explanation/index'
import InputName from '@/components/HabitName/InputName'
import ButtonSetName from '@/components/HabitName/ButtonSetName'

export default {
  components: {
    Explanation,
    InputName,
    ButtonSetName
  }
}
</script>
