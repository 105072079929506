<template>
  <list-basic
    :index="index"
    store="today"
    :caption="item.get('name')"
    :action="done"
    :checkbox="true"
    :checked="item.get('done')"
    :description="item.get('smallAction')"
    :foldable="true"
    folded-component="today"
    :folded-item="item"
  />
</template>

<script>
import ListBasic from '@/lib/List/Basic/index'

export default {
  components: {
    ListBasic
  },

  props: {
    index: {
      required: true,
      type: Number
    },

    item: {
      required: true,
      type: Object
    }
  },

  methods: {
    async done () {
      this.$store.commit('today/setModel', this.item)
      const result = await this.$store.dispatch('today/save')

      if (result) this.$store.dispatch('today/getList')
    }
  }
}
</script>
