import getList from './getList'
import save from './save'
import saveOrder from './saveOrder'
import reset from './reset'

export default {
  ...getList,
  ...save,
  ...saveOrder,
  ...reset
}
