<template>
  <div class="tab">
    <icon-home />
    <icon-todo />
    <icon-habit />
    <icon-other />
  </div>
</template>

<script>
import IconHome from './Icons/Home'
import IconTodo from './Icons/Todo'
import IconHabit from './Icons/Habit'
import IconOther from './Icons/Other'

export default {
  components: {
    IconHome,
    IconTodo,
    IconHabit,
    IconOther
  }
}
</script>

<style lang="scss" scoped>
.tab {
  border-top: 1px solid #d2dae2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
}
</style>
