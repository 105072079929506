import Habit from '@/views/Habit'
import store from '@/store/index'
import beforeEnter from './beforeEnter'

export default {
  path: '/habit',
  name: 'habit',
  component: Habit,

  meta: {
    title: '習慣'
  },

  beforeEnter: beforeEnter((params) => {
    store.dispatch('habit/getList', params)
  })
}
