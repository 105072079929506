<template>
  <button-center
    caption="名前を設定する"
    :action="setHabitName"
  />
</template>

<script>
import ButtonCenter from '@/lib/Button/Center'

export default {
  components: {
    ButtonCenter
  },

  methods: {
    setHabitName () {
      this.$router.back()
    }
  }
}
</script>
