import getters from './getters'
import actions from './actions'

const state = {
  password: ''
}

const mutations = {
  setPassword (state, password) {
    state.password = password
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
