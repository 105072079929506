const state = {
  noNav: [
    'root',
    'thank-you',
    'login',
    'signup'
  ],

  noTab: [
    'root',
    'thank-you',
    'login',
    'signup'
  ]
}

const getters = {
  noNav: (state) => (route) => {
    return !(state.noNav.find(r => route === r))
  },

  noTab: (state) => (route) => {
    return !(state.noTab.find(r => route === r))
  }
}

export default {
  namespaced: true,
  state,
  getters
}
