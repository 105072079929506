<template>
  <draggable
    class="habit-list"
    v-model="habits"
    :delay="$store.state.draggable.delay"
    @start="vibrate"
  >
    <list-item
      v-for="(h, i) in habits"
      :key="i"
      :index="i"
      :item="h"
    />
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'
import ListItem from './Item'

export default {
  components: {
    draggable,
    ListItem
  },

  created () {
    this.$store.dispatch('today/getList')
  },

  computed: {
    habits: {
      get () {
        return this.$store.state.today.list
      },

      set (value) {
        this.$store.dispatch('today/saveOrder', value)
      }
    }
  },

  methods: {
    vibrate () {
      window.navigator.vibrate(100)
    }
  }
}

</script>
