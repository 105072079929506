<template>
  <button-basic
    :action="action"
    caption="終わった！"
  />
</template>

<script>
import ButtonBasic from '@/lib/Button/Basic'

export default {
  components: {
    ButtonBasic
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  methods: {
    async action () {
      const result = await this.$store.dispatch('today/save', this.item)
      if (result) this.$store.dispatch('today/getList')
    }
  }
}
</script>
