<template>
  <div class="description">{{ description }}
  </div>
</template>

<script>
export default {
  props: {
    description: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.description {
  font-size: 0.85rem;
  color: #34495e;
  white-space: pre-wrap;
}
</style>
