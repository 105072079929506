<template>
  <div class="message">
    <div
      class="conguraturation"
    >{{ conguraturation }}
    </div>

    <div
      class="done-all"
    >{{ doneAll }}
    </div>

    <div v-if="false">{{ more }}
    </div>
  </div>
</template>

<script>
import emoji from 'node-emoji'

export default {
  computed: {
    conguraturation () {
      return `おめでとうございます！`
    },

    doneAll () {
      return `今日の習慣をすべて終えました🎉`
    },

    more () {
      // return emoji.get(':smile:')
      const thinking = emoji.get(':thinking_face:')
      return `もっと習慣を進めたいですか？${thinking}`
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  text-align: center;
}

.conguraturation {
  margin-bottom: 16px;
  font-size: 1.6rem;
  font-weight: 500;
}

.done-all {
  margin-bottom: 10px;
  font-size: 1.1rem;
  font-weight: 500;
}
</style>
