<template>
  <button-center
    caption="代わりの行動を設定する"
    :action="setHabitAlternateAction"
  />
</template>

<script>
import ButtonCenter from '@/lib/Button/Center'

export default {
  components: {
    ButtonCenter
  },

  methods: {
    setHabitAlternateAction () {
      this.$router.back()
    }
  }
}
</script>
