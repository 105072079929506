import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faHome,
  faCalendarAlt,
  faCheck,
  faList,
  faSignOutAlt,
  faKey,
  faInfoCircle,
  faArrowLeft,
  faPlus,
  faTrashAlt,
  faSyncAlt,
  faUser,
  faAngleDown,
  faAngleUp
} from '@fortawesome/free-solid-svg-icons'

import {
  far
} from '@fortawesome/free-regular-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  far,
  faHome,
  faCalendarAlt,
  faCheck,
  faList,
  faSignOutAlt,
  faKey,
  faInfoCircle,
  faArrowLeft,
  faPlus,
  faTrashAlt,
  faSyncAlt,
  faUser,
  faAngleDown,
  faAngleUp
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
