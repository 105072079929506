<template>
  <div class="other">
    <logout />
    <password-change />
    <info />
  </div>
</template>

<script>
import Logout from '@/components/Other/Logout'
import PasswordChange from '@/components/Other/PasswordChange'
import Info from '@/components/Other/Info'

export default {
  components: {
    Logout,
    PasswordChange,
    Info
  }
}
</script>
