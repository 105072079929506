<template>
  <div
    ref="textarea"
    class="input"
  >{{ model }}
  </div>
</template>

<script>
import Mixin from './Mixin'

export default {
  mixins: [
    Mixin
  ],

  computed: {
    model: {
      get () {
        const data = this.get()
        return data
      }
    }
  },

  mounted () {
    this.adjustHeight()
  }
}
</script>

<style lang="scss" scoped>
.input {
  border-bottom: 1px solid #2980b9;
  width: 100%;
  padding: 10px;
  padding-left: 2px;
  font-size: 1.0rem;
  overflow: hidden;
  white-space: pre-wrap;
  overflow: hidden;
}
</style>
