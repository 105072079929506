<template>
  <list-icon
    caption="パスワード変更"
    icon="key"
    :action="passwordChange"
  />
</template>

<script>
import ListIcon from '@/lib/List/Icon/index'

export default {
  components: {
    ListIcon
  },

  methods: {
    passwordChange () {
      this.$router.push({ name: 'password-change' })
    }
  }
}
</script>
