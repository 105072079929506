<template>
  <div class="caption">
    <div>
      {{ caption }}
    </div>

    <font-awesome-icon
      v-if="icon"
      :icon="icon"
      @click="exec"
    />
  </div>
</template>

<script>
export default {
  props: {
    caption: {
      required: true,
      type: String
    },

    icon: {
      type: String
    },

    action: {
      type: Function
    }
  },

  methods: {
    exec () {
      this.action()
    }
  }
}
</script>

<style lang="scss" scoped>
.caption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 10px;
  color: #2c3e50;
}
</style>
