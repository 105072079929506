<template>
  <button-basic
    :action="action"
    caption="習慣を始める"
    icon="list"
  />
</template>

<script>
import ButtonBasic from '@/lib/Button/Basic'

export default {
  components: {
    ButtonBasic
  },

  methods: {
    action () {
      this.$store.commit('home/setComponent', 'guide')
    }
  }
}
</script>
