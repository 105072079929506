import Todo from '../views/Todo'
import beforeEnter from './beforeEnter'

export default {
  path: '/todo',
  name: 'todo',
  component: Todo,
  meta: {
    title: 'やること'
  },
  beforeEnter: beforeEnter()
}
