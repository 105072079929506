import getList from './getList'
import setAttrs from './setAttrs'
import resetAttrs from './resetAttrs'
import save from './save'
import saveOrder from './saveOrder'
import destroy from './destroy'
import focus from './focus'
import focusout from './focusout'

export default {
  ...getList,
  ...setAttrs,
  ...resetAttrs,
  ...save,
  ...saveOrder,
  ...destroy,
  ...focus,
  ...focusout
}
