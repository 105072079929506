<template>
  <list-input-textarea
    :get="get"
    :set="set"
  />
</template>

<script>
import ListInputTextarea from '@/lib/List/Input/Textarea/index'

export default {
  components: {
    ListInputTextarea
  },

  computed: {
    get () {
      return () => {
        return this.$store.state.habit.description
      }
    },

    set () {
      return (value) => {
        this.$store.commit('habit/setDescription', value)
      }
    }
  }
}
</script>
