import Parse from '@/plugins/parse'

async function purge () {
  const query = new Parse.Query('Today')
  const list = await query.find()
  return Parse.Object.destroyAll(list)
}

function find () {
  const query = new Parse.Query('Habit')
  return query.find()
}

function duplicate (list) {
  const array = []
  for (const l of list) {
    const d = l.toJSON()
    delete d.objectId
    d.done = false
    array.push(new Parse.Object('Today', d))
  }
  return Parse.Object.saveAll(array)
}

export default {
  async reset ({ commit, dispatch }) {
    try {
      dispatch('loading/show', null, { root: true })
      await purge()
      const list = await find()
      await duplicate(list)
      dispatch('loading/hide', null, { root: true })
      commit('setIsReloadRequired', true)
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }
}
