import HabitNew from '../views/HabitNew'
import beforeEnter from './beforeEnter'

export default {
  path: '/habit-new',
  name: 'habit-new',
  component: HabitNew,
  meta: {
    title: '習慣',
    isSubview: true
  },
  beforeEnter: beforeEnter()
}
