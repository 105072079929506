<template>
  <div
    class="the-nav"
    :class="classNavStyle"
  >

    <div class="layout-left">
      <icon-back v-if="isSubview" />
      <span class="title">
        {{ title }}
      </span>
    </div>
    <icon-delete v-if="canDelete" />
  </div>
</template>

<script>
import IconBack from './IconBack'
import IconDelete from './IconDelete'

export default {
  components: {
    IconBack,
    IconDelete
  },

  computed: {
    isSubview () {
      return this.$store.state.nav.isSubview
    },

    canDelete () {
      return this.$store.state.nav.canDelete
    },

    title () {
      return this.$store.state.nav.title
    },

    classNavStyle () {
      if (this.isSubview) return ''
      return 'mainview'
    }
  }
}
</script>

<style lang="scss" scoped>
.the-nav {
  width: 100vw;
  height: 64px;
  background: #2c3e50;
  color: white;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mainview {
  justify-content: center;
}

.title {
  font-weight : 500;
  font-size: 1.1rem;
}

.layout-left {
  display: flex;
  align-items: center;
}
</style>
