<template>
  <div
    v-if="hasTodayList"
    class="home"
  >
    <todo-done v-if="isDoneAll" />
    <todo-start v-else />
  </div>
</template>

<script>
import TodoDone from '@/components/Home/TodoDone'
import TodoStart from '@/components/Home/TodoStart/index'

export default {
  components: {
    TodoDone,
    TodoStart
  },

  computed: {
    hasTodayList () {
      return this.$store.state.today.list.length !== 0
    },

    isDoneAll () {
      return this.$store.getters['today/isDoneAll']
    }
  },

  created () {
    if (!this.hasTodayList) this.$store.dispatch('today/getList')
    this.$store.dispatch('home/reset')
  }
}
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
