export default {
  list: [],
  model: null,

  name: '',
  smallAction: '',
  alternateAction: '',
  description: '',
  focus: {
    description: false
  },

  isReloadRequired: true,
  isLoadingRequired: true
}
