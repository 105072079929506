import Calendar from '../views/Calendar'
import beforeEnter from './beforeEnter'

export default {
  path: '/calendar',
  name: 'calendar',
  component: Calendar,
  meta: {
    title: 'カレンダー'
  },
  beforeEnter: beforeEnter()
}
