import Parse from '@/plugins/parse'

const state = {
  id: '',
  password: ''
}

const mutations = {
  setId (state, id) {
    state.id = id
  },

  setPassword (state, password) {
    state.password = password
  }
}

const actions = {
  async exec ({ dispatch }) {
    const user = new Parse.User()
    user.setUsername(state.id)
    user.setPassword(state.password)

    try {
      await user.signUp()
      dispatch(
        'toast/show',
        'ユーザー登録が完了しました',
        { root: true }
      )
      return true
    } catch (error) {
      console.log(error.code, error)
      return false
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
