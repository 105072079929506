import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

import root from './root'
import thankYou from './thankYou'
import login from './login'
import signup from './signup'

import home from './home'
import todo from './todo'
import calendar from './calendar'
import habit from './habit'
import other from './other'
import info from './info'
import logout from './logout'
import passwordChange from './passwordChange'

import habitNew from './habitNew'
import habitEdit from './habitEdit'
import habitName from './habitName'
import habitSmallAction from './habitSmallAction'
import habitAlternateAction from './habitAlternateAction'
import habitDescription from './habitDescription'

Vue.use(VueRouter)

const routes = [
  root,
  thankYou,
  login,
  signup,

  home,
  todo,
  calendar,
  habit,
  other,
  logout,
  passwordChange,
  info,
  habitNew,
  habitEdit,
  habitName,
  habitSmallAction,
  habitAlternateAction,
  habitDescription
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('nav/setTitle', to.meta.title)
  store.commit('nav/setIsSubview', to.meta.isSubview)
  store.commit('nav/setCanDelete', to.meta.canDelete)
  next()
})
export default router
