<template>
  <font-awesome-icon
    :icon="icon"
    class="icon"
    size="2x"
    @click="switchFolded"
  />
</template>

<script>
export default {
  props: {
    index: {
      required: true,
      type: Number
    },

    store: {
      required: true,
      type: String
    }
  },

  computed: {
    isExpanded () {
      return this.$store.state[this.store].list[this.index].isExpanded
    },

    icon () {
      if (this.isExpanded) return 'angle-up'
      return 'angle-down'
    }
  },

  methods: {
    switchFolded () {
      this.$store.commit(`${this.store}/switchIsExpanded`, this.index)
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  margin-right: 16px;
  width: 24px;
}
</style>
