import Parse from '@/plugins/parse'

export default {
  async save ({ state, dispatch }) {
    const user = await Parse.User.current()
    user.set('password', state.password)
    try {
      await user.save()
      dispatch(
        'toast/show',
        'パスワードを変更しました。',
        { root: true }
      )
      return true
    } catch (error) {
      console.log(error)
    }
  }
}
