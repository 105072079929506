import Other from '../views/Other'
import beforeEnter from './beforeEnter'

export default {
  path: '/other',
  name: 'other',
  component: Other,
  meta: {
    title: 'その他'
  },
  beforeEnter: beforeEnter()
}
