<template>
  <div class="container">

    <line-main
      :index="index"
      :store="store"
      :caption="caption"
      :description="description"
      :checked="checked"
      :checkbox="checkbox"
      :foldable="foldable"
      :action="action"
    />

    <slide-up-down
      :active="foldable && isExpanded"
      :duration="300"
    >
      <line-folded
        :folded-component="foldedComponent"
        :folded-item="foldedItem"
      />
    </slide-up-down>
  </div>
</template>

<script>
import LineMain from './LineMain'
import LineFolded from './LineFolded'
import SlideUpDown from 'vue-slide-up-down'

export default {
  components: {
    LineMain,
    LineFolded,
    SlideUpDown
  },

  props: {
    index: {
      type: Number
    },

    store: {
      type: String
    },

    caption: {
      type: String
    },

    description: {
      type: String
    },

    checkbox: {
      type: Boolean
    },

    checked: {
      type: Boolean
    },

    foldable: {
      type: Boolean
    },

    foldedComponent: {
      type: String
    },

    foldedItem: {
      type: Object
    },

    action: {
      type: Function
    }
  },

  computed: {
    isExpanded () {
      return this.$store.state[this.store].list[this.index].isExpanded
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  $size: 16px;
  padding-top: $size;
  padding-bottom: $size;
  margin-left: $size;
  margin-right: $size;

  cursor: pointer;
  border-bottom: 1px solid #d2dae2;

  display: flex;
  flex-direction: column;
}
</style>
