<template>
  <div class="todo-start">
    <message />
    <button-start />
  </div>
</template>

<script>
import Message from './Message'
import ButtonStart from './ButtonStart'

export default {
  components: {
    Message,
    ButtonStart
  }
}
</script>

<style lang="scss" scoped>
.todo-start {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
