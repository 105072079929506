import HabitName from '../views/HabitName'
import beforeEnter from './beforeEnter'

export default {
  path: '/habit-name',
  name: 'habit-name',
  component: HabitName,
  meta: {
    title: '習慣の編集',
    isSubview: true
  },
  beforeEnter: beforeEnter()
}
