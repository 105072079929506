<template>
  <textarea
    class="reset input"
    v-model="model"
    rows="1"
    ref="textarea"
  >
  </textarea>
</template>

<script>
import Mixin from './Mixin'

export default {
  mixins: [
    Mixin
  ],

  computed: {
    model: {
      get () {
        return this.get()
      },

      set (value) {
        this.set(value)
        this.adjustHeight()
      }
    }
  },

  mounted () {
    this.$refs.textarea.focus()
    this.adjustHeight()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_reset.scss';

.input {
  border-bottom: 1px solid #2980b9;
  width: 100%;
  padding: 10px;
  padding-left: 2px;
  font-size: 1.0rem;
  overflow: hidden;
}
</style>
