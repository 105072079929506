<template>
  <div class="message">
    {{ message }}
  </div>
</template>

<script>
import emoji from 'node-emoji'

export default {
  computed: {
    message () {
      return emoji.emojify('この習慣を終わらせましょう！:fist:')
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  font-size: 1.1rem;
  font-weight: 500;
}
</style>
