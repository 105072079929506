import Parse from '@/plugins/parse'

export default {
  async getList ({ state, commit, dispatch }) {
    if (!state.isReloadRequired) return
    const query = new Parse.Query('Habit')
    query.ascending('order')

    if (state.isLoadingRequired) dispatch('loading/show', null, { root: true })
    const list = await query.find()
    if (state.isLoadingRequired) dispatch('loading/hide', null, { root: true })
    commit('setList', list)
    commit('setIsReloadRequired', false)
    commit('setIsLoadingRequired', true)
  }
}
