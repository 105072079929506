<template>
  <div class="habit">
    <habit-tab />
    <habit-list class="layout-habit-list" />
  </div>
</template>

<script>
import HabitTab from '@/components/Habit/Tab/index'
import HabitList from '@/components/Habit/List/index'

export default {
  components: {
    HabitTab,
    HabitList
  }
}
</script>

<style lang="scss" scoped>
.layout-habit-list {
  height: calc(100% - 64px);
}
</style>
