<template>
  <div class="tab">
    <icon-left />
    <tab-items />
    <icon-right />
  </div>
</template>

<script>
import TabItems from './Items'
import IconLeft from './IconLeft'
import IconRight from './IconRight'

export default {
  components: {
    IconLeft,
    TabItems,
    IconRight
  }
}
</script>

<style lang="scss" scoped>
.tab {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 64px;
  width: 100vw;
  border-bottom: 2px solid #bdc3c7;
}
</style>
