<template>
  <div class="habit-small-action">
    <explanation
      caption="小さな行動"
      description="苦にならない最小単位の行動を設定してください。"
    />
    <input-small-action />
    <button-set-small-action />
  </div>
</template>

<script>
import Explanation from '@/lib/Explanation/index'
import InputSmallAction from '@/components/HabitSmallAction/InputSmallAction'
import ButtonSetSmallAction from '@/components/HabitSmallAction/ButtonSetSmallAction'

export default {
  components: {
    Explanation,
    InputSmallAction,
    ButtonSetSmallAction
  }
}
</script>
