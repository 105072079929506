export default {
  async destroy ({ state, commit, dispatch }) {
    try {
      dispatch('loading/show', null, { root: true })
      await state.model.destroy()
      dispatch('loading/hide', null, { root: true })

      commit('setIsReloadRequired', true)
      commit('setIsLoadingRequired', false)
      dispatch(
        'toast/show',
        '習慣を削除しました。',
        { root: true }
      )
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }
}
