export default {
  setList (state, list) {
    state.list = list
  },

  setModel (state, model) {
    state.model = model
  },

  setName (state, name) {
    state.name = name
  },

  setSmallAction (state, smallAction) {
    state.smallAction = smallAction
  },

  setAlternateAction (state, alternateAction) {
    state.alternateAction = alternateAction
  },

  setDescription (state, description) {
    state.description = description
  },

  setIsReloadRequired (state, isReloadRequired) {
    state.isReloadRequired = isReloadRequired
  },

  setIsLoadingRequired (state, isLoadingRequired) {
    state.isLoadingRequired = isLoadingRequired
  },

  setFocus (state, payload) {
    state.focus[payload.name] = payload.value
  }
}
