import PasswordChange from '../views/PasswordChange'
import beforeEnter from './beforeEnter'

export default {
  path: '/password-change',
  name: 'password-change',
  component: PasswordChange,
  meta: {
    title: 'その他',
    isSubview: true
  },
  beforeEnter: beforeEnter()
}
