<template>
  <button-center
    caption="パスワードを変更する"
    :action="changePassword"
    :disabled="disabled"
  />
</template>

<script>
import ButtonCenter from '@/lib/Button/Center'

export default {
  components: {
    ButtonCenter
  },

  computed: {
    disabled () {
      return this.$store.getters['passwordChange/disabled']
    }
  },

  methods: {
    async changePassword () {
      const result = await this.$store.dispatch('passwordChange/save')
      if (!result) return
      this.$router.push({ name: 'logout' })
    }
  }
}
</script>
