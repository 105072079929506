import Home from '../views/Home'
import beforeEnter from './beforeEnter'

export default {
  path: '/home',
  name: 'home',
  component: Home,
  meta: {
    title: 'ホーム'
  },
  beforeEnter: beforeEnter()
}
