<template>
  <div class="root">
    <title-without-login>
      小さな習慣アプリ
    </title-without-login>

    <message-basic>
      このサービスはスマホ向けに作られています。<br>
      ぜひ、ホーム画面に追加してご利用ください。
    </message-basic>

    <img
      class="app-icon"
      src="@/assets/img/app-icon.svg"
    >
    <button-center
      :action="showPrompt"
      caption="ホーム画面に追加する"
    />
  </div>
</template>

<script>
import TitleWithoutLogin from '@/lib/Title/WithoutLogin'
import MessageBasic from '@/lib/Message/Basic'
import ButtonCenter from '@/lib/Button/Center'

export default {
  components: {
    TitleWithoutLogin,
    MessageBasic,
    ButtonCenter
  },

  data () {
    return {
      installPromptEvent: null
    }
  },

  created () {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      this.$router.push({ name: 'home' })
    }

    window.addEventListener('beforeinstallprompt', (event) => {
      this.installPromptEvent = event
      event.userChoice.then(choice => {
        if (choice.outcome === 'accepted') {
          this.$router.push({ name: 'thank-you' })
        }
      })
    })
  },

  methods: {
    showPrompt () {
      this.installPromptEvent.prompt()
    }
  }
}
</script>

<style lang="scss" scoped>
.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-icon {
  width: 96px;
  height: 96px;
}
</style>
