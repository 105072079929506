<template>
  <div>
    <font-awesome-icon
      v-if="checked"
      :icon="['far', 'check-square']"
      size="lg"
    />
    <font-awesome-icon
      v-else
      :icon="['far', 'square']"
      size="lg"
    />
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean
    }
  }
}
</script>
