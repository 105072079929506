<template>
  <div
    class="icon-container"
    @click="exec"
    :class="classSelected"
  >
    <icon :icon="icon" />
    <icon-caption :caption="caption" />
  </div>
</template>

<script>
import Icon from './Icon'
import IconCaption from './Caption'

export default {
  components: {
    Icon,
    IconCaption
  },

  props: {
    caption: {
      type: String
    },

    icon: {
      type: String
    },

    route: {
      type: String
    },

    action: {
      type: Function
    },

    params: {
      type: Object
    }
  },

  computed: {
    classSelected () {
      if (this.$route.name === this.route) return 'selected'
      return ''
    }
  },

  methods: {
    exec () {
      if (this.action) this.action()
      this.$router.push({
        name: this.route,
        params: this.params
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  cursor: pointer;
}

.selected {
  color: #575fcf;
}
</style>
