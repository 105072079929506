import Parse from '@/plugins/parse'

export default {
  async saveOrder ({ commit }, list) {
    list.forEach((item, i) => {
      item.set('order', i + 1)
    })

    try {
      commit('setList', list)
      await Parse.Object.saveAll(list)
    } catch (error) {
      console.log(error)
    }
  }
}
