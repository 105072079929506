import { render, staticRenderFns } from "./Items.vue?vue&type=template&id=04d8179c&scoped=true&"
var script = {}
import style0 from "./Items.vue?vue&type=style&index=0&id=04d8179c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04d8179c",
  null
  
)

export default component.exports