<template>
  <div class="icon-left">
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.icon-left {
  width: 64px;
  height: 64px;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
