<template>
  <div class="todo">
    <list-caption
      caption="今日やること"
      class="layout-caption"
      icon="sync-alt"
      :action="reset"
    />

    <habit-list class="layout-habit-list" />
  </div>
</template>

<script>
import ListCaption from '@/lib/List/Caption'
import HabitList from '@/components/Todo/Today/List/index'

export default {
  components: {
    ListCaption,
    HabitList
  },

  computed: {
    message () {
      return '習慣を反映させると今日のやることがリセットされます。'
    }
  },

  methods: {
    async reset () {
      if (window.confirm(this.message)) {
        const result = await this.$store.dispatch('today/reset')
        if (result) this.$store.dispatch('today/getList')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.todo {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: scroll;
}

.layout-help {
  margin-top: 6px;
  margin-left: 10px;
  margin-right: 10px;
}
</style>
