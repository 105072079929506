<template>
  <input
    class="reset input"
    type="password"
    v-model="password"
  >
</template>

<script>
export default {
  computed: {
    password: {
      get () {
        return this.$store.state.login.password
      } ,

      set (password) {
        this.$store.commit('login/setPassword', password)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_input.scss';
</style>
