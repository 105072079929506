<template>
  <component
    :is="component"
  />
</template>

<script>
import Start from './Start/index'
import Guide from './Guide/index'

export default {
  components: {
    Start,
    Guide
  },

  computed: {
    component () {
      return this.$store.state.home.component
    }
  }
}
</script>
