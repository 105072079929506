<template>
  <div class="caption">
    {{ caption }}
  </div>
</template>

<script>
export default {
  props: {
    caption: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.caption {
  font-size: 0.8rem;
}
</style>
