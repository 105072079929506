<template>
  <div class="message">
    {{ message }}
  </div>
</template>

<script>
import emoji from 'node-emoji'

export default {
  computed: {
    message () {
      return emoji.emojify('さあ、今日の習慣を始めましょう！:muscle:')
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>
