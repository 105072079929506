<template>
  <div class="input-container">
    <input
      class="reset input"
      :placeholder="placeholder"
      :type="type"
      v-model="model"
    >
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text'
    },

    placeholder: {
      type: String,
      default: ''
    },

    get: {
      type: Function
    },

    set: {
      type: Function
    }
  },

  computed: {
    model: {
      get () {
        return this.get()
      },

      set (value) {
        this.set(value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_reset.scss';

.input-container {
  padding: 10px;
}

.input {
  border-bottom: 1px solid #2980b9;
  width: 100%;
  padding: 10px;
  padding-left: 2px;
  font-size: 1.0rem;
}
</style>
