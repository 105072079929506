<script>
export default {
  computed: {
    notYetHabits () {
      return this.$store.getters['today/notYetHabits']
    },

    habit () {
      return this.notYetHabits[0]
    }
  }
}
</script>
