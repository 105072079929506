<template>
  <button-center
    caption="小さな行動を設定する"
    :action="setHabitSmallAction"
  />
</template>

<script>
import ButtonCenter from '@/lib/Button/Center'

export default {
  components: {
    ButtonCenter
  },

  methods: {
    setHabitSmallAction () {
      this.$router.back()
    }
  }
}
</script>
