<template>
  <div class="name">
    {{ habit.get('name') }}
  </div>
</template>

<script>
import Mixin from './Mixin'

export default {
  mixins: [
    Mixin
  ]
}
</script>

<style lang="scss" scoped>
.name {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
}
</style>
