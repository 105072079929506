<template>
  <draggable
    class="habit-list"
    v-model="habits"
    :delay="$store.state.draggable.delay"
    @start="vibrate"
  >
    <list-basic
      v-for="(h, i) in habits"
      :key="i + 1"
      :caption="h.get('name')"
      :action="edit(h)"
    />
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'
import ListBasic from '@/lib/List/Basic/index'

export default {
  components: {
    draggable,
    ListBasic
  },

  computed: {
    habits: {
      get () {
        return this.$store.state.habit.list
      },

      set (value) {
        this.$store.dispatch('habit/saveOrder', value)
      }
    }
  },

  methods: {
    vibrate () {
      window.navigator.vibrate(100)
    },

    edit (habit) {
      return () => {
        this.$store.dispatch('habit/setAttrs', habit)
        this.$router.push({ name: 'habit-edit' })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.habit-list {
  overflow: scroll;
}
</style>
