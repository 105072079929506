<template>
  <list-icon
    caption="情報"
    icon="info-circle"
    :action="action"
  />
</template>

<script>
import ListIcon from '@/lib/List/Icon/index'

export default {
  components: {
    ListIcon
  },

  methods: {
    action () {
      this.$router.push({ name: 'info' })
    }
  }
}
</script>
