<script>
export default {
  props: {
    get: {
      type: Function
    },

    set: {
      type: Function
    }
  },

  methods: {
    adjustHeight () {
      const textarea = this.$refs.textarea
      const resetHeight = new Promise((resolve) => {
        resolve(textarea.style.height = 'auto')
      })
      resetHeight.then(() => {
        textarea.style.height = textarea.scrollHeight + 'px'
      })
    }
  }
}
</script>
