<template>
  <div class="icon-container">
    <font-awesome-icon
      icon="trash-alt"
      @click="exec"
    />
  </div>
</template>

<script>
export default {
  methods: {
    exec () {
      this.$store.state.nav.deleteAction()
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-container {
  height: 64px;
  width: 64px;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
